@import 'themes-vars';
@import 'plugins/plugins';

.w-full {
    width: 100%;
}

/* breadcrumb */
.icon-breadcrumb {
    margin-right: 5px;
}

.link-breadcrumb {
    font-size: 0.75rem;
    display: flex;
    text-decoration: none;
}

/* card */

.card-header {
    font-weight: 600 !important;
    font-size: 15px;
}

.apexcharts-legend-marker {
    margin-right: 8px !important;
}

.MuiCard-root {
    &.bg-primary {
        background-color: $primary;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $primary;
            color: #fff;
        }
    }
    &.bg-secondary {
        background-color: $secondary;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $secondary;
            color: #fff;
        }
    }
    &.bg-error {
        background-color: $error;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $error;
            color: #fff;
        }
    }
    &.bg-warning {
        background-color: $warning;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $warning;
            color: #fff;
        }
    }
    &.bg-info {
        background-color: $info;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $info;
            color: #fff;
        }
    }
    &.bg-success {
        background-color: $success;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $success;
            color: #fff;
        }
    }
    &.bg-dark {
        background-color: $dark;
        color: #fff;
        .MuiCardHeader-root {
            background-color: $dark;
            color: #fff;
        }
    }
    &.outline-primary {
        border: 1px solid + $primary;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $primary;
        }
    }
    &.outline-secondary {
        border: 1px solid $secondary;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $secondary;
        }
    }
    &.outline-error {
        border: 1px solid $error;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $error;
        }
    }
    &.outline-warning {
        border: 1px solid $warning;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $warning;
        }
    }
    &.outline-info {
        border: 1px solid $info;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $info;
        }
    }
    &.outline-success {
        border: 1px solid $success;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $success;
        }
    }
    &.outline-dark {
        border: 1px solid $dark;
        .MuiCardHeader-root {
            border-bottom: 1px solid + $dark;
        }
    }
}

.f-right {
    float: right;
}
.p-0 {
    padding: 0 !important;
}
.p-20 {
    padding: 20px !important;
}

.responsive-table-card {
    .MuiTableCell-root {
        white-space: unset;
    }
}



// backgrounds
.custom-style-wrap {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center
}

.custom-style-wrap>img {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 0
}

.custom-style-wrap>img:not([src]) {
    display: none !important
}

.custom-style-wrap>img.color-correct-img {
    position: relative;
    z-index: 1
}

.custom-style-wrap>img.outline-half-img {
    z-index: 2 !important
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.hero-photo-item {
    position: relative;
    cursor: pointer;
    object-position: center;
    align-content: center;
    display: flex;
    min-height: 250px;
    img {
        width: 100%;
        max-height: 300px;
        object-fit: contain;
        
        vertical-align: middle;
    }
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: 0.3s;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        p {
            font-size: 20px;
            margin-bottom: 6px;
        }
        a {
            margin: 5px auto;
            text-transform: capitalize;
            color: #fff;
            background-color: #3366FF;
            padding: 4px 10px;
            font-size: 0.8125rem;
            text-decoration: unset;
            box-shadow: 0px 3px 1px -2px RGB(0 0 0 / 20%), 0px 2px 2px 0px RGB(0 0 0 / 14%), 0px 1px 5px 0px RGB(0 0 0 / 12%);
            min-width: 64px;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 1.75;
            border-radius: 4px;
            &:last-child {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
            &:hover {
                background-color: #0043a9;
            }
        }
    }
    &:hover {
        .overlay {
            opacity: 1;
        }
    }
}

.email-history-icon {
    margin: 0px 2px;
    border: 1px solid #242c3a;
    border-radius: 50px;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 15px;
        height: 15px;
    }
}

.email-history-icon-active {
    background-color: #242c3a;
    cursor: pointer;
    svg path {
        fill: #fff;
    }
}

// all table spacing
.MuiTableCell-head {
    padding: 11px !important;
    position: relative !important;
    font-weight: 500 !important;
}
.MuiTableCell-root {
    padding: 11px !important;
}

#editor-history-dropdown {
    padding: 15px 8px;
    table {
        width: 100%;
        border-collapse: collapse;
        tr {
            td, th {
                border-right: 1px solid #e0e0e0;
                border-bottom: 1px solid #e0e0e0;
                font-size: 13px;
                &:last-child {
                    border-right: unset;
                }
            }
            td {
                padding: 6px 8px;
            }
            &:last-child td {
                border-bottom: unset;
            }
        }
    }
}
.guidleline-image {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-size: cover;
 }

.tox-notifications-container {
    display: none !important;
}
li.MuiListItem-container:has(.team-tag-container) {
    display: flex;
}
.MuiTypography-subtitle2{
    white-space: wrap;
    overflow-wrap: break-word;
}
@media (min-width: 600px){
.MuiTabs-flexContainer .MuiTab-root {
    min-width: 125px;
}
}
.resubmit-photo-view .canvas-container {
    max-height: 500px;
    canvas{
        max-height: 500px;
        object-fit: contain;
    }
    @media (max-width: 1300px) {
        zoom: 0.8;
    }
    @media (max-width: 1100px) {
        zoom: 0.7;
    }
}
.MuiSelect-selectMenu#demo-mutiple-chip-tag-filters{
    white-space: inherit;
}